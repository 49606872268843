.footer {
    color: var(--bs-heading-color);
  }
  .footer .links ul {
    list-style-type: none;
  }
  .footer .links li a {
    color: var(--bs-heading-color);
    transition: color 0.2s;
  }
  .footer .links li a:hover {
    text-decoration: none;
    color: #4180CB;
  }
  .footer .about-company i {
    font-size: 25px;
  }
  .footer .about-company a {
    color: var(--bs-heading-color);
    transition: color 0.2s;
  }
  .footer .about-company a:hover {
    color: var(--bs-primary-rgb);
  }
  .footer .location i {
    font-size: 18px;
  }
  .footer .copyright p {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  