@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

:root {
}
:root, [data-bs-theme=light] {
  --bs-primary-rgb: 137,192,126;
  --bs-dark-rgb: 42,63,63;
  --bs-font-monospace: 'Roboto' ,ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --bs-body-font-family: var(--bs-font-monospace);
  --bs-light-rgb: 70,119,80;
}

.btn-primary {
  --bs-btn-bg: #89c07e;
  --bs-btn-border-color: #89c07e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #467750;
  --bs-btn-hover-border-color: #467750;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #467750;
  --bs-btn-active-border-color: #467750;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #89c07e;
  --bs-btn-disabled-border-color: #89c07e
}

.btn-outline-primary {
  --bs-btn-color: #89c07e;
  --bs-btn-border-color: #89c07e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #89c07e;
  --bs-btn-hover-border-color: #89c07e;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #89c07e;
  --bs-btn-active-border-color: #89c07e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #89c07e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #89c07e;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #2a3f3f;
  --bs-btn-border-color: #2a3f3f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2a3f3f;
  --bs-btn-hover-border-color: #2a3f3f;
  --bs-btn-focus-shadow-rgb: 33,37,41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a3f3f;
  --bs-btn-active-border-color: #2a3f3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2a3f3f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2a3f3f;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #FFF;
  --bs-btn-border-color: #FFF;
  --bs-btn-hover-color: #2a3f3f;
  --bs-btn-hover-bg: #FFF;
  --bs-btn-hover-border-color: #FFF;
  --bs-btn-focus-shadow-rgb: 13,202,240;
  --bs-btn-active-color: #2a3f3f;
  --bs-btn-active-bg: #FFF;
  --bs-btn-active-border-color: #FFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFF;
  --bs-gradient: none;
}

.list-group-item {
   background-color: var(--bs-dark-rgb);
   color: var(--bs-white);
}

.h-50vh {
  min-height: 50vh !important;
}

.vh-75 {
  min-height: 75vh !important;
}