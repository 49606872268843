.hero-section-backgroud 
{
    background-image: url('../../assets/herosection.png'); background-size: cover; 
    background-position: center;
}

.hero-section-overlayer 
{
    height: 100vh; 
    max-height: 750px;
    background-color: rgba(0, 0, 0, 0.5);
}

.hero-section-content{
    top: 0; right: 0; bottom: 0; left: 0;
}